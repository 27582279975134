import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DisplaySavedMessage } from './DisplaySavedMessage'
import { AddRuralReturn } from './AddRuralReturn';
import { useState} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Placeholder from 'react-bootstrap/Placeholder';
import { GetAccessToken } from "../index";
import { useMsal } from "@azure/msal-react";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

export function AddResidentialReturn() {
    const initialResidentialState = {
        OfficeReinzId: null,
        ReturnType: "Residential",
        SaleIsConjoint: false,
        ConjointOfficeReinzId: null,
        ConjointOfficeReinzIds:null,
        AdminNotes: null,
        ReturnPeriod: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear(),
        ExternalId: null,
        AddressSearch: null,
        ValRef: null,
        Unit: null,
        Suffix: null,
        StreetNumber: null,
        Street: null,
        Suburb: null,
        PostalCode: 0,
        Category: null,
        /*Title:"",*/
        FreeholdStatus: null,
        FloorArea: 0,
        LandArea: 0,
        Bedrooms: 0,
        Bathrooms: 0,
        ParkingTotal: 0,
        IsNewDwelling: false,
        IsSaleAsIs: false,
        IsOwnerOccupied: false,
        IsFirstHomeBuyer: false,
        IsMortgageSale: false,
        IsInvestmentOrRental: false,
        Held: false,
        Suppressed: false,
        HasPool: false,
        IsGstExempt: false,
        DepositSaleOnly: false,
        SaleMethod: null,
        ListPrice: 0,
        SalePrice: 0,
        ListDate: null,
        AgreementDate: null,
        UnconditionalDate: null,
        SettlementDate: null,
        Valuation: null,
        ValuationDate: null,
        LandValue: null,
        ValuationImprovement: null,
        //fields used by API's but not by forms
        Latitude: null,
        Longitude: null,
        LinzTlaId: null,
        TlaId: null,
        CertificateOfTitle: null,
        TownCity: null,
        LandClass: null,
    }
    const { instance} = useMsal();
    const [showRural, setshowRural] = useState(false);
    const [message, setMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchAddressResults, setAddressSearchResults] = useState([]);
    const [searchSuburbResults, setSuburbSearchResults] = useState([]);
    const [searchOrgResults, setOrgSearchResults] = useState([]);
    const [searchOrgsResults, setOrgsSearchResults] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedSuburb, setSelectedSuburb] = useState(null);
   /* const [selectedOrg, setSelectedOrg] = useState(null);*/
    const [isAddressVisible, setAddressIsVisible] = useState(true);
    const [isSuburbVisible, setSuburbIsVisible] = useState(true);
    const [isOrgVisible, setOrgIsVisible] = useState(true);
    const [isOrgsVisible, setOrgsIsVisible] = useState(false);
    const handleClose = () => setShowModal(false);
    const [residential, setresidential] = useState(initialResidentialState);
    const newresidential = { ...residential }
    var addressEntered = "";
    var suburbEntered = "";
    var orgEntered = "";
    const isClicked = () => {
        if (showRural === true) {
            setshowRural(false);
        } else {
            setshowRural(true);
        }
        handleClose();
    }
    function clearInputData() {
        setresidential({
            initialResidentialState
        })
    }

    const [showLastMonths] = useState([
        {
            key: 1,
            value: (new Date((new Date()).setMonth((new Date()).getMonth()))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth()))).getFullYear()
        },
        {
            key: 2,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 1))).getFullYear()
        },
        {
            key: 3,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 2))).getFullYear()
        },
        {
            key: 4,
            value: (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getMonth() + 1 + '/' + (new Date((new Date()).setMonth((new Date()).getMonth() - 3))).getFullYear()
        }
    ]);

    function handle(e) {
        const newresidential = { ...residential }
        newresidential[e.target.id] = e.target.value;
        setresidential(newresidential)
        console.log(newresidential)
    }
    
    function checkboxHandle(e) {
        const { name, value, checked, type } = e.target;
        setresidential
            (
                prevData =>
                ({
                    ...prevData,
                    [name]: type ===
                        'checkbox'? checked : value
                }));
    }
    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    useEffect(() => {
        const fetchAgency = async () => {
            const url = "api/ReturnList/GetAgencyName";
            const tokenRequest = {
                scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
                account: instance.getActiveAccount()
            };
            const accessToken = await GetAccessToken(tokenRequest);

            try {
                await fetch(url,
                    {
                        method: "GET",
                        headers: new Headers(
                            {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${accessToken}`
                            }),
                    })
                    .then((response) => {
                        console.log(response, 'response');
                        if (response.status !== 200 && response.status !== 201) {
                            console.log("not ok" + response.status);
                        }
                        else {
                            return response.json()
                        }
                    })
                    .then(data => {
                        console.log(data)
                        if (data !== null && data !== undefined) {
                            newresidential.OfficeReinzId = data;
                            setresidential(newresidential);
                        }
                        console.log(newresidential);
                    })
                    //setIsLoading(false)
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            catch (error) {
                if (error.response) {
                    console.log(error);
                }
            }
        }
        fetchAgency();
    },[])
    
    function handleAddressSearch(e) {
        const { id } = e.target;
        addressEntered = e.target.value;
        /*setSelectedAddress(addressEntered);*/
        setAddressIsVisible(true);
        if (addressEntered.length >= 6) {
        handleAddress();
        }
    }
    function handleSuburbSearch(e) {
        const { id } = e.target;
        suburbEntered = e.target.value;
        setSelectedSuburb(suburbEntered);
        if (suburbEntered.length >= 4) {
            handleSuburb();
        }
    }
    function handleConjointOfficesSearch(e) {
        const { id } = e.target;
        orgEntered = e.target.value;
        /*setSelectedOrg(orgEntered);*/
        if (orgEntered.length >= 4) {
        handleConjointOffices();
        }
    }
   
    const handleAddress = async () => {
        const url = "api/address/GetAddress";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(addressEntered)
            })
                .then((response) => {
                     console.log(response, 'response');
                    if (response.status !==200  && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else
                    {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setAddressSearchResults(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching addresses', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }

    }
    const handleSuburb = async () => {
        const url = "api/address/GetSuburb";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(suburbEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setSuburbSearchResults(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching suburbs', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }

    }
    const handleConjointOffices = async () => {
        const url = "api/organisation/GetOrganisation";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        try {
            fetch(url, {
                method: "POST",
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(orgEntered)
            })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201)
                    {
                        console.log("not ok" + response.status);
                    }
                    else
                    {
                        return response.json()
                    }
                }).then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined)
                    {
                        setOrgSearchResults(data);
                        setOrgIsVisible(true);
                    }
                })
                .catch(error => {
                    console.error('Error fetching organisations', error);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }
    const Submit = async (e) => {
        e.preventDefault();
        //alert("Hello1");
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        scrollTop();
        const url = "api/ResidentialReturn/PostResidentialReturn";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };

        try {
            const accessToken = await GetAccessToken(tokenRequest);
            //fetch
            const response = await fetch(url,
                {
                    method: 'POST',
                    //mode: "no-cors",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(residential),
                });
            if (!response.ok) {
                console.log("not ok" + response.status);
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setMessage(true);
            //setresidential(initialResidentialState); // Resetting state

            setTimeout(() => {
                setMessage(false);
            }, 5000);

        } catch (error) {
            console.log(error);
            setMessage('Error saving data. Please try again.');
        }
    };
            
        const handleSelectAddress = (address) => {
        /*setSelectedAddress(address);*/
        setAddressIsVisible(!isAddressVisible);
        newresidential.ValRef = address.valRef;
        newresidential.AddressSearch = address.addressSearch;
        newresidential.Unit = address.unit;
        newresidential.Suffix = address.suffix;
        newresidential.StreetNumber = address.streetNumber.toString();
        newresidential.Street = address.street;
        newresidential.Suburb = address.suburbName;
        newresidential.TownCity = address.townCity;
        newresidential.PostalCode = address.postcode;
        newresidential.LandValue = address.landValue;
        newresidential.Longitude = address.longitude;
        newresidential.Latitude = address.latitude;
        newresidential.Valuation = address.councilValuation;
        newresidential.Bathrooms = address.bathrooms;
        newresidential.Bedrooms = address.bedrooms;
        newresidential.FloorArea = address.floorArea;
        newresidential.LandArea = address.landArea;
        newresidential.ValuationImprovement = address.improvementValue;
        newresidential.CertificateOfTitle = address.certificateOfTitle;
        setresidential(newresidential);
    };
    const handleSelectSuburb = (suburb) => {
        setSuburbIsVisible(false);
        newresidential.Suburb = suburb;
        setresidential(newresidential);
    };

    const handleSelectOrg = (org) => {
        setOrgIsVisible(!isOrgVisible);

        if (org&&!searchOrgsResults.includes(org.trim())) {
            setOrgsSearchResults((searchOrgsResults) => {
                const updatedOrgsList = [...searchOrgsResults, org.trim() ]
                // Update state
                setresidential((prevData) => ({ ...prevData, ConjointOfficeReinzId: org }));
                /*setOrgSearchResults(['']);*/
                return updatedOrgsList;// Return the updated list for the state
            });
        }
        setOrgsIsVisible(true);
    };

    const handleRemoveSearchTerm = (org) => {
        setOrgsSearchResults((searchOrgsResults) => {
            const updatedOrgsList = searchOrgsResults.filter((searchorg) => searchorg !== org)
            const updatedResidential = {
                ...newresidential,
                ConjointOfficeReinzId: updatedOrgsList
            };
            // Update state
            setresidential(updatedResidential);
            return updatedOrgsList;// Return the updated list for the state
        });
    }
   
    
    const renderTooltip_returntype = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Changing this to a Rural Return will remove all residential specific fields
        </Tooltip>
    );

    const renderTooltip_saleisconjoint = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Marking a sale as conjoint will allow REINZ to match this sale with returns submitted by participating offices
        </Tooltip>
    );

    const renderTooltip_returnperiod = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            Return Period will be determined by the date entered in the Unconditional Date field below
        </Tooltip>
    );

    const renderTooltip_externalid = (props) => (
        <Tooltip  {...props} className="tooltipColor">
        If your agency requires your return to contain an ID for reporting purposes, enter it here
        </Tooltip>
    );

    const renderTooltip_addresssearch = (props) => (
        <Tooltip  {...props} className="tooltipColor">
        Selecting an address with this search box will overwrite any changes you've made to this form
        </Tooltip>
    );

    const renderTooltip_saleasis = (props) => (
        <Tooltip  {...props} className="tooltipColor">
        In real estate, 'sale as is' denotes selling of a property without guarantees or warranties about its condition, often seen in cases of natural disasters or significant events.
        </Tooltip>
    );


    const renderTooltip_holdsale = (props) => (
    <Tooltip  {...props} className="tooltipColor">
    Holding a sale will prevent it being searchable in the unconditional sale month.
    Holding a sale will not enable real time sales searching for your agency.
    </Tooltip>
    );

    const renderTooltip_supresssale = (props) => (
        <Tooltip  {...props} className="tooltipColor">
        Supressing a sale prevents it being searchable until the sale has settled or 6 months.
        Supressing sales is actively monitored by REINZ and you will be asked the reason why it has supressed.
        Supressing should only be done for legal privacy reasons.
        </Tooltip>
    );

    const renderTooltip_unconditionaldate = (props) => (
        <Tooltip  {...props} className="tooltipColor">
        Unconditional Date will be used to determine which Return Period this return will be submitted under.
        </Tooltip>
    );

    const renderTooltip_listdate = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            The date which agency signed agreement with vendor to list property.
        </Tooltip>
    );

    const renderTooltip_agreementdate = (props) => (
        <Tooltip  {...props} className="tooltipColor">
            The date which sale and purchase agreement was signed.
        </Tooltip>
    );

    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to change this return from residential to a rural return?</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will remove all residential data from the return form</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Cancel
                </Button>
                <Button style={{ backgroundColor: "#331b8c" }} onClick={isClicked}>
                Confirm
                </Button>
                </Modal.Footer>
            </Modal>
            {showRural? <AddRuralReturn />:
                <>
                    <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} />
                    <Form noValidate validated={validated}><br />
                        {message === true ? <DisplaySavedMessage /> : null/*<div style={{ color: "red", textAlign: "center", fontWeight: "bold" }}>ERROR Saving Data...</div>*/}
                        {/*Primary Property Information*/}
                        <Form.Label><h4>Primary Property Information</h4></Form.Label><br />
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Company<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <Form.Label required>{residential.OfficeReinzId}</Form.Label>
                            {residential.OfficeReinzId === null &&
                            <Form.Control as="label" className="required">
                            Company is required
                            </Form.Control>}
                            <br />
                            </Col>

                            {/*Return Type*/}
                            <Form.Label column sm={2}>Return Type
                            <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_returntype}>
                            <sup>?</sup>
                            </OverlayTrigger>
                            </Form.Label>

                            <Col sm={10}>
                            <Form.Label><span id="ReturnType" value={residential.ReturnType} onChange={(e) => handle(e)}>Residential</span></Form.Label>&nbsp;&nbsp;&nbsp;
                            <Button style={{ backgroundColor: "#331b8c" }} onClick={() => setShowModal(true)}><i className="bi bi-arrow-repeat iconStyles"></i>&nbsp;Change Return Type</Button>
                            </Col>

                            <Form.Label column sm={2}>Sale Is Conjoint
                                <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip_saleisconjoint}>
                                <sup>?</sup>
                                </OverlayTrigger></Form.Label>
                            <Col sm={10}>
                                <Form.Check type="checkbox" checked={residential.SaleIsConjoint} name="SaleIsConjoint" onChange={(e) => checkboxHandle(e)} />
                            </Col>
                            
                            <Form.Label column sm={2}>Conjoint Companies</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text style={{ background: "transparent" }}>
                                    <i className="bi bi-search iconStyles"></i>
                                    </InputGroup.Text>
                                    <Form.Control type="text" id="ConjointOfficeReinzId" placeholder="Start typing to search through Agencies" value={residential.ConjointOfficeReinzId||null} onChange={(e) => handleConjointOfficesSearch(e)} />
                                </InputGroup>
                                {isOrgVisible &&
                                    <ul id="ConjointOfficeReinzId">
                                        {searchOrgResults.map((org, index) => (
                                            <li key={index} onClick={() => handleSelectOrg(org)}>
                                                {org}
                                            </li>
                                        ))}
                                    </ul>
                                }
                                {isOrgsVisible &&
                                    <ul id="ConjointOfficeReinzId">
                                        {searchOrgsResults.map((org, index) => (
                                            <li key={index}>
                                                <button style={{ backgroundColor: "#331b8c", color: "white" }} onClick={() => handleRemoveSearchTerm(org)}><i className="bi bi-trash3-fill"></i>&nbsp;Remove</button>&nbsp;&nbsp;&nbsp;
                                                <span>{org}</span>
                                                
                                            </li>
                                        ))}
                                    </ul>
                                }<br />
                            </Col>

                            <Form.Label column sm={2}>REINZ Admin Notes</Form.Label>
                            <Col sm={10}>
                                <Form.Control as="textarea" row="2" value={residential.AdminNotes||null} name="AdminNotes" id="AdminNotes" onChange={(e) => handle(e)} /><br />
                            </Col>

                             <Form.Label column sm={2}>Return Period
                             <OverlayTrigger
                             placement="top"
                             overlay={renderTooltip_returnperiod}>
                             <sup>?</sup>
                             </OverlayTrigger>
                             </Form.Label>
                            <Col sm={10}>
                                <Form.Select type="select" id="ReturnPeriod" value={residential.ReturnPeriod} onChange={(e) => handle(e)}>
                                    {showLastMonths.map((info) => (
                                    <option key={info.key}>{info.value}</option>
                                    ))}
                                </Form.Select><br />
                             {/*<p>{residential.UnconditionalDate.toString().substring(0, 7) }</p>*/}
                             {/*{residential.UnconditionalDate.toString() === "" &&*/}
                             {/*<>*/}
                             {/*<span>Return Period requires Unconditional Date to be set</span>*/}
                             {/*<Form.Control as="label" className="required">Return Period is required</Form.Control><br />*/}
                            {/* </>*/}
                            {/*}          */}
                             </Col>


                            {/*External ID*/}
                            <Form.Label column sm={2}>External ID
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip_externalid}>
                                    <sup>?</sup>
                                </OverlayTrigger>
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="ExternalId" value={residential.ExternalId||null} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Address Search*/}
                            <Form.Label column sm={2}>Address Search
                            <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_addresssearch}>
                            <sup>?</sup>
                            </OverlayTrigger></Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}>
                            <i className="bi bi-search iconStyles"></i>
                            </InputGroup.Text>
                            <Form.Control type="text" autoComplete="street-address" placeholder="Start typing to search through addresses" id="AddressSearch" name="AddressSearch" value={residential.AddressSearch||null} onChange={(e) => handleAddressSearch(e)} minLength={6}></Form.Control>
                            </InputGroup>

                            {selectedAddress === null &&
                            <p>Selecting an address with this search box will overwrite any changes you've made to this form</p>
                            }
                                {isAddressVisible &&
                                    <ul id="addressSearch">
                                        {searchAddressResults.map((address) => (
                                            <li key={address.id} onClick={() => handleSelectAddress(address)}>
                                                {address.addressSearch}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            {/*Hide the dropdown on address search*/}
                            {/*{residential.AddressSearch!== null&&*/}
                            {/*<Form.Select>*/}
                            {/*<option>{residential.AddressSearch}</option>*/}
                            {/*</Form.Select>*/}
                            {/*}*/}
                            {/*<span style={{ fontSize: "12px", fontWeight:"bold", color:"red" }}>No such address is found. Please enter a different address.</span>*/}
                            
                            <br />
                            </Col>

                            {/*Val Ref*/}
                            <Form.Label column sm={2}>Val Ref</Form.Label>
                            <Col sm={10}>
                            <Form.Control type="text" placeholder="Example  22900/42602" id="ValRef" value={residential.ValRef||null} onChange={(e) => handle(e)}></Form.Control>
                            <br />
                            </Col>

                            {/* Unit Number*/}
                            <Form.Label column sm={2}>Unit Number</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Unit" value={residential.Unit||null} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Suffix*/}
                            <Form.Label column sm={2}>Suffix</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Suffix" value={residential.Suffix||null} onChange={(e) => handle(e)} placeholder="Example A"></Form.Control><br />
                            </Col>

                            {/*Street Number*/}
                            <Form.Label column sm={2}>Street Number<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="StreetNumber" value={residential.StreetNumber||null} onChange={(e) => handle(e)} required ></Form.Control>
                                {residential.StreetNumber === null &&
                                    <Form.Control as="label" className="required">Street Number is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Street Name*/}
                            <Form.Label column sm={2}>Street Name<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="Street" value={residential.Street||null} onChange={(e) => handle(e)} required ></Form.Control>
                                {residential.Street === null &&
                                    <Form.Control as="label" className="required" value="street_name">Street Name is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Suburb*/}
                            <Form.Label column sm={2}>Suburb<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" placeholder="Start typing to search through suburbs" id="suburbName" value={residential.Suburb||null} onChange={(e) => handleSuburbSearch(e)} required minLength={4}></Form.Control>
                                {residential.Suburb === null &&
                                    <Form.Control as="label" className="required">Suburb is required</Form.Control>
                                }
                                {isSuburbVisible &&
                                    <ul id="suburbName">
                                        {searchSuburbResults.map((suburb) => (
                                            <li key={suburb} onClick={() => handleSelectSuburb(suburb)}>
                                                {suburb}
                                            </li>
                                        ))}
                                    </ul>
                                }<br />
                            </Col>

                            {/*Post Code*/}
                            <Form.Label column sm={2}>Post Code</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" id="PostalCode" value={residential.PostalCode||null} onChange={(e) => handle(e)}></Form.Control><br />
                            </Col>

                            {/*Property Category*/}
                            <Form.Label column sm={2}>Property Category<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Select id="Category" value={residential.Category||null} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option value="Residence">Residence</option>
                                <option value="Residential Section">Residential Section</option>
                                <option value="Unit">Unit</option>
                                <option value="Apartment">Apartment</option>
                                <option value="Townhouse">Townhouse</option>
                                <option value="Home and Income">Home and Income</option>
                                <option value="Residential Investment Block">Residential Investment Block</option>
                                <option value="Residential- Other">Residential- Other</option>
                                </Form.Select>
                                {residential.Category === null &&
                                <Form.Control as="label" className="required">Property Category is required</Form.Control>
                                }<br />
                            </Col>

                            {/*Title*/}
                            <Form.Label column sm={2}>Title</Form.Label>
                            <Col sm={10}>
                            
                            <Form.Control type="text" id="CertificateOfTitle" value={residential.CertificateOfTitle||null} onChange={(e) => handle(e)} ></Form.Control>
                            <br />
                            </Col>

                            {/*Latitude*/}
                            <div style={{ display: "none" }}>
                            <Form.Label column sm={2}>Latitude</Form.Label>
                            <Col sm={10}>
                            <Form.Control type="text" id="Latitude" value={residential.Latitude||null} onChange={(e) => handle(e)}></Form.Control>
                            <br />
                            </Col>
                            </div>

                            {/*Longitude*/}
                            <div style={{display:"none"} }>
                            <Form.Label column sm={2}>Longitude</Form.Label>
                            <Col sm={10}>
                            <Form.Control type="text" id="Longitude" value={residential.Longitude||null} onChange={(e) => handle(e)}></Form.Control> 
                            <br/>
                            </Col>
                            </div>
              
                            {/*Freehold Status*/}
                            <Form.Label column sm={2}>Freehold Status<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                                <Form.Select id="FreeholdStatus" value={residential.FreeholdStatus||null} onChange={(e) => handle(e)} required>
                                <option></option>
                                <option>Unknown</option>
                                <option>Freehold</option>
                                <option>Leasehold</option>
                                <option>Crosslease</option>
                                <option>Unit Title</option>
                                <option>Company</option>
                                <option>Strata</option>
                                </Form.Select>
                                {residential.FreeholdStatus === null &&
                                <Form.Control as="label" className="required">Freehold Status is required</Form.Control>
                                }<br />
                            </Col>
                        </Form.Group>

                        {/*Residential Information*/}
                        <Form.Label><h4>Residential Information</h4></Form.Label><br />

                        <Form.Group as={Row}>
                        <Form.Label column sm={2}>Floor Area</Form.Label>
                        <Col sm={10}>
                        <InputGroup>
                        <Form.Control type="number" id="FloorArea" value={residential.FloorArea||0} onChange={(e) => handle(e)} />
                        <Dropdown as={ButtonGroup} id=" FloorArea" value={residential.FloorArea}>
                        <Dropdown.Toggle><sub>m</sub>2</Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item eventKey="1"><sub>m</sub>2</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Ha</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                        </InputGroup>
                        {residential.Category === 'Residence' && residential.FloorArea === null &&
                        <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                        }
                        {residential.Category === 'Unit' && residential.FloorArea === null &&
                        <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                        }
                        {residential.Category === 'Townhouse' && residential.FloorArea === null &&
                        <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                        }
                        {residential.Category === 'Home and Income' && residential.FloorArea === null &&
                        <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                        }
                        {residential.Category === 'Apartment' && residential.FloorArea === null &&
                        <Form.Control as="label" className="required">Floor Area is required</Form.Control>
                        }<br />

                        </Col>

                        <Form.Label column sm={2}>Land Area</Form.Label>
                        <Col sm={10}>
                        <InputGroup>
                        <Form.Control type="number" id="LandArea" value={residential.LandArea||0} onChange={(e) => handle(e)}/>
                        <Dropdown as={ButtonGroup} >
                        <Dropdown.Toggle id="LandArea" value={residential.LandArea} onChange={(e) => handle(e)}><sub>m</sub>2</Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item eventKey="1"><sub>m</sub>2</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Ha</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                        </InputGroup>
                        {residential.Category === 'Residential Section' &&
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                        }
                        {residential.Category === 'Residence' &&
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                        }
                        {residential.Category === 'Unit' &&
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                        }
                        {residential.Category === 'Townhouse' &&
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                        }
                        {residential.Category === 'Home and Income' &&
                            <Form.Control as="label" className="required">Land Area is required</Form.Control>
                        }<br />
                        </Col>

                        <Form.Label column sm={2}>No. of Bedrooms</Form.Label>
                        <Col sm={10}>
                        <Form.Control type="number" id="Bedrooms" value={residential.Bedrooms||0} onChange={(e) => handle(e)}/>
                        {residential.Category === 'Apartment' &&
                            <Form.Control as="label" className="required">No. of Bedrooms is required</Form.Control>
                        }<br />
                        </Col>

                        <Form.Label column sm={2}>No. of Bathrooms</Form.Label>
                        <Col sm={10}>
                        <Form.Control type="number" id="Bathrooms" value={residential.Bathrooms||0} onChange={(e) => handle(e)} />
                   
                        {residential.Category === 'Residence' &&
                            <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                        }
                        {residential.Category === 'Unit' &&
                            <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                        }
                        {residential.Category === 'Townhouse' &&
                            <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                        }
                        {residential.Category === 'Home and Income' &&
                            <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                        }
                        {residential.Category === 'Apartment' &&
                            <Form.Control as="label" className="required">No. of Bathrooms is required</Form.Control>
                        }<br />
                        </Col>

                        <Form.Label column sm={2}>Total Parking</Form.Label>
                        <Col sm={10}>
                        <Form.Control type="number" id="ParkingTotal" value={residential.ParkingTotal||0} onChange={(e) => handle(e)} />
                        {residential.Category === 'Residence' &&
                            <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                        }
                        {residential.Category === 'Unit' &&
                            <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                        }
                        {residential.Category === 'Townhouse' &&
                            <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                        }
                        {residential.Category === 'Home and Income' &&
                            <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                        }
                        {residential.Category === 'Apartment' &&
                            <Form.Control as="label" className="required">Total Parking is required</Form.Control>
                        }<br />
                        </Col>

                        <Form.Label column sm={1}>New Dwelling</Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsNewDwelling} name="IsNewDwelling" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Sale As Is
                        <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip_saleasis}>
                        <sup>?</sup>
                        </OverlayTrigger></Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsSaleAsIs} name="IsSaleAsIs" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Owner is Occupier </Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsOwnerOccupied} name="IsOwnerOccupied" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>First Home Buyer</Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsFirstHomeBuyer} name="IsFirstHomeBuyer" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Mortgage Sale </Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsMortgageSale} name="IsMortgageSale" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Investment or Rental </Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsInvestmentOrRental} name="IsInvestmentOrRental" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Hold Sale
                        <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip_holdsale}>
                        <sup>?</sup>
                        </OverlayTrigger></Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.Held} name="Held" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Supress Sale
                        <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip_supresssale}>
                        <sup>?</sup>
                        </OverlayTrigger></Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.Suppressed} name="Suppressed" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Deposit Sale Only</Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.DepositSaleOnly} name="DepositSaleOnly" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>Has Pool</Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.HasPool} name="HasPool" onChange={(e) => checkboxHandle(e)} />
                        </Col>

                        <Form.Label column sm={1}>GST Exempt</Form.Label>
                        <Col sm={2}>
                        <Form.Check type="checkbox" checked={residential.IsGstExempt} name="IsGstExempt" onChange={(e) => checkboxHandle(e)} />
                        </Col>
                    </Form.Group>

                        {/*Additional Information*/}
                            <Form.Label><h4>Additional Information</h4></Form.Label><br />

                            <Form.Group as={Row}>
                            <Form.Label column sm={2}>Sale Method<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <Form.Select id="SaleMethod" value={residential.SaleMethod||null} onChange={(e) => handle(e)} required>
                            <option></option>
                            <option>P - Private Treaty(Neg.)</option>
                            <option>A - Auction</option>
                            <option>T - Tender</option>
                            </Form.Select>  
                            <Form.Control as="label" className="required">
                            Sale Method is required
                            </Form.Control><br />
                            </Col>


                            <Form.Label column sm={2}>List Price</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="ListPrice" value={residential.ListPrice} onChange={(e) => handle(e)} />
                            </InputGroup>
                            {residential.SaleMethod === 'P - Private Treaty(Neg.)' && residential.SaleMethod === "" &&
                            <Form.Control as="label" className="required">
                            List Price is required
                            </Form.Control>
                            }<br />

                            </Col>

                            <Form.Label column sm={2}>Sale Price<sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="SalePrice" value={residential.SalePrice} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">SalePrice is required</Form.Control><br />
                            </Col>

                            <Form.Label column sm={2}>List Date <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_listdate}>
                            <sup>?</sup>
                            </OverlayTrigger><sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" placeholder="Enter a date or click the icon to open the datepicker" id="ListDate" value={residential.ListDate||null} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">ListDate is required</Form.Control><br />
                            {residential.ListDate > residential.AgreementDate &&
                            <Form.Control as="label" className="required">
                            List Date cannot be greater than Agreement Date
                            </Form.Control>
                            }
                            {residential.ListDate > residential.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            List Date cannot be greater than Unconditional Date
                            </Form.Control>
                            }<br />
                            </Col>

                            <Form.Label column sm={2}>Agreement Date <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_agreementdate}>
                            <sup>?</sup>
                            </OverlayTrigger><sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="AgreementDate" value={residential.AgreementDate||null} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">Agreement Date is required</Form.Control>
                            {residential.AgreementDate > residential.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            Agreement Date cannot be greater than Unconditional Date
                            </Form.Control>
                            }<br />
                            </Col>

                            <Form.Label column sm={2}>Unconditional Date
                            <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip_unconditionaldate}>
                            <sup>?</sup>
                            </OverlayTrigger><sup className="aestric">*</sup></Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="UnconditionalDate" value={residential.UnconditionalDate||null} onChange={(e) => handle(e)} required/>
                            </InputGroup>
                            <Form.Control as="label" className="required">Unconditional Date is required</Form.Control>
                            <br />
                            </Col>

                            <Form.Label column sm={2}>Settlement Date</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="date" id="SettlementDate" value={residential.SettlementDate||null} onChange={(e) => handle(e)} format={"DD/MM/YYYY"} />
                            </InputGroup>
                            {residential.SettlementDate < residential.UnconditionalDate &&
                            <Form.Control as="label" className="required">
                            Settlement Date cannot be lesser than Unconditional Date
                            </Form.Control>
                            }<br />
                            </Col>

                            <Form.Label column sm={2}>Current Valuation</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="Valuation" value={residential.Valuation||null} onChange={(e) => handle(e)} />
                            </InputGroup><br/>
                            </Col>

                            <Form.Label column sm={2}>Valuation Date</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <Form.Control type="month" id="ValuationDate" value={residential.ValuationDate||null} onChange={(e) => handle(e)} />
                            </InputGroup><br/>
                            </Col>

                            <Form.Label column sm={2}>Land Value</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="LandValue" value={residential.LandValue||null} onChange={(e) => handle(e)} />
                            </InputGroup><br/>
                            </Col>

                            <Form.Label column sm={2}>Improvement Value</Form.Label>
                            <Col sm={10}>
                            <InputGroup>
                            <InputGroup.Text style={{ background: "transparent" }}><i className="bi bi-currency-dollar iconStyles"></i></InputGroup.Text>
                            <Form.Control type="number" id="ValuationImprovement" value={residential.ValuationImprovement||null} onChange={(e) => handle(e)} />
                            
                            </InputGroup><br/>
                            </Col>
                         </Form.Group>
                        {/*<Button variant="primary" type="submit" disabled>*/}
                        {/*File Return*/}
                        {/*</Button>&nbsp;&nbsp;*/}
                        <Button style={{ backgroundColor: "#331b8c" }} onClick={Submit}>
                        File Return
                        </Button><br />
                    </Form>
                </>
            }
        </>
        
        );
    
    }

const residential_test = {
    "OfficeReinzId": "10045569*0",
    "ReturnType": "Residential",
    "SaleIsConjoint": true,
    "ConjointOfficeReinzId": "",
    "AdminNotes": "",
    "ExternalId": "FRONTEND_TEST",
    "AddressSearch": "10",
    "ValRef": "22900/123",
    "Unit": "9",
    "Suffix": "A",
    "StreetNumber": "9",
    "Street": "Bellenden Crescent",
    "Suburb": "Pokeno",
    "PostalCode": "2402",
    "Category": "Residence",
    "FreeholdStatus": "Freehold",
    "FloorArea": "201",
    "landArea": 0,
    "Bedrooms": "4",
    "Bathrooms": "2",
    "ParkingTotal": 0,
    "IsNewDwelling": false,
    "IsSaleAsIs": false,
    "IsOwnerOccupied": false,
    "IsFirstHomeBuyer": true,
    "IsMortgageSale": false,
    "IsInvestmentOrRental": false,
    "Held": false, "Suppressed": false,
    "HasPool": false,
    "IsGstExempt": false,
    "DepositSaleOnly": false,
    "SaleMethod": "P - Private Treaty(Neg.)",
    "ListPrice": "1080000",
    "SalePrice": "1080000",
    "ListDate": "2024-01-17",
    "AgreementDate": "2024-03-17",
    "UnconditionalDate": "2024-05-01",
    "SettlementDate": "2024-05-10",
    "Valuation": "780000",
    "ValuationDate": null,
    "LandValue": "320000",
    "ValuationImprovement": "460000",
    "Latitude": "-37.24759466",
    "Longitude": "175.01296848",
    "LinzTlaId": 0,
    "TlaId": 0,
    "CertificateOfTitle": "800173",
    "Title": "800173",
    "ReturnPeriod": "06/2024",
    "TownCity": "",
    "LandClass": "",
    "LandArea": "609",
    "councilValuation": "780000",
}